// Import the Bootstrap bundle
//
// This includes Popper and all of Bootstrap's JS plugins.

import { Dropdown, Modal } from "bootstrap";

(function () {
  // Function to get cookie value by name
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }
    return null;
  }

  // Function to set cookie with expiration
  function setCookie(name, value) {
    // Set cookie to expire in 30 minutes
    const expirationDate = new Date();
    expirationDate.setMinutes(expirationDate.getMinutes() + 30);
    document.cookie = `${name}=${value};expires=${expirationDate.toUTCString()};path=/;Secure;SameSite=Lax`;
  }

  // Reset cookie expiration on user activity
  function resetCookieExpiration() {
    Object.entries(utmParams).forEach(([key, value]) => {
      setCookie(key, value);
    });
  }

  // Reset cookie expiration on page load and user activity
  ["load", "mousemove", "keydown", "click", "scroll", "touchstart"].forEach(
    (eventType) => {
      document.addEventListener(eventType, resetCookieExpiration, {
        passive: true,
      });
    },
  );

  // Default UTM values if none found
  const defaultUtmParams = {
    utm_source: "marketing-site",
    utm_medium: "website",
    utm_campaign: "organic",
    utm_term: "",
    utm_content: "",
  };

  // Get UTM parameters from URL first
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams = ["source", "medium", "campaign", "term", "content"]
    .map((param) => `utm_${param}`)
    .reduce((acc, param) => {
      // Check URL parameters first
      let value = urlParams.get(param);

      // If found in URL, save to cookie
      if (value) {
        setCookie(param, value);
      } else {
        // If not in URL, try to get from cookie
        value = getCookie(param);

        // If still no value, use default without saving to cookie
        if (!value) {
          value = defaultUtmParams[param];
        }
      }

      acc[param] = value;
      return acc;
    }, {});

  function sanitizeUtmValue(value) {
    if (!value) return value;
    // 1. Encode special characters
    value = encodeURIComponent(value);
    // 2. Only allow specific characters
    value = value.replace(/[^\w\d\-_\.]/g, "");
    // 3. Limit length
    value = value.slice(0, 100);
    // 4. Validate against allowed patterns
    const validPattern = /^[\w\d\-_\.]+$/;
    return validPattern.test(value) ? value : "";
  }

  // Add UTM parameters to outbound links
  document.querySelectorAll("a").forEach((link) => {
    const href = link.getAttribute("href");
    if (href && href.startsWith("https://")) {
      const url = new URL(href);
      Object.entries(utmParams).forEach(([key, value]) => {
        url.searchParams.set(key, sanitizeUtmValue(value));
      });
      link.setAttribute("href", url.toString());
    }
  });
})();
